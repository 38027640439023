<template>
	<div>
		<div class="dashboard">
			<div class="container">
				<transition name="fadeStop">
          <Loader v-if="!realLeads || realLeads.length == 0" />
        </transition>

        <h1>Leads</h1>
        <hr>

				<div class="list__item" v-for="item in realLeads" :key="item.id">
					<div>
	          <h4>{{item.name}}<span class="light"> // {{item.date | moment("MMM Do YYYY")}} // {{item.status}}</span></h4>
	        </div>
					<div class="list__button">
	          <router-link :to="`/dashboard/` + item.id">
	            <button class="btn btn__outlined ma-2">View</button>
	          </router-link>
	          
	        </div>
				</div>
			</div>
		</div>
		<Footer :user="currentUser" />
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'dashboard',
  computed: {
    ...mapState(['currentUser', 'leads']),
    realLeads: function() {
      return this.leads.filter(function(lead) {
        return lead.date = new Date(lead.created.seconds * 1000)
      })
    }
  },
  components: {
    Footer,
    Loader
  }
}
</script>